<template>
  <v-dialog v-model="active" width="350">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" :color="buttonColor">
        <v-icon v-if="!!icon" :left="!!icon && text">{{ icon }}</v-icon>
        {{ text || 'Signature' }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ title || 'Enter signature below' }}</v-card-title>
      <v-card-text>
        <canvas ref="signatureRef" style="border:1px solid rgba(0,0,0,0.42);background-color:white;"></canvas>
        <v-text-field v-if="showEnterName" ref="nameRef" v-model="name" label="Enter Name" :rules="[ !!name || 'Name is required' ]"></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Cancel</v-btn>
        <v-btn text @click="save">Save</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="clear">Clear</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, watch } from '@vue/composition-api'
import SignaturePad from 'signature_pad'

export default {
  props: {
    icon: String,
    text: String,
    title: {
      type: String,
      default: 'Enter signature below'
    },
    showEnterName: Boolean,
    buttonColor: String
  },
  setup (props, { root, emit }) {
    const active = ref(false)
    const signatureRef = ref(null)
    const signaturePad = ref(null)
    const name = ref('')
    const nameRef = ref(null)

    watch(active, () => {
      if (active.value) {
        signaturePad.value = new SignaturePad(signatureRef.value)
      }
    })

    function save () {
      if (signaturePad.value.toData().length === 0) {
        alert('No signature was entered. Cannot save without a signature.')
        return
      }
      let obj = { signature: signaturePad.value.toDataURL() }
      if (props.showEnterName) {
        if (name.value === '') {
          nameRef.value.focus()
          return
        }
        obj.name = name.value
      }
      obj.date = new Date()
      emit('save', obj)
      active.value = false
    }

    function clear () {
      signaturePad.value.clear()
    }

    return { active, signatureRef, signaturePad, name, nameRef, save, clear }
  }
}
</script>
